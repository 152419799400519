.navbar-custom {
  background-color: #171717 !important;
}
.navbar-custom .nav-link {
  color: white;
}
.navbar-nav .nav-link.active {
  color: white;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1.5px solid #188aec;
}
.navUser {
  margin-left: 1rem;
  .dropdown-toggle {
    margin-right: 0.5rem;
  }
  .dropdown-toggle::after {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
}
.dropdown-menu {
  border-radius: 0.5rem;
  background: #171717;
  li,
  a {
    color: #e7e7e8;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    img {
      width: 1.1rem;
      margin-right: 0.5rem;
      height: 1.3rem;
    }
  }
  a {
    justify-content: flex-start;
    display: flex;
  }
}
.dropdown-item {
  align-items: baseline;
}
.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: #413df6;
  color: #e7e7e8;
}
.signin-btn {
  background-color: #413df6 !important;
}
.home-card {
  background-color: #171717;
  width: 100%;
  flex-direction: row;
  display: flex;
  height: 742px;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  @media #{$xs-layout} {
    padding-left: 2.375rem;
    padding-right: 2.375rem;
  }
  .text-card {
    margin-left: 7.8rem;
    margin-top: 5rem;
    @media #{$xs-layout} {
      margin-left: 1rem;
      margin-top: 0rem;
    }
  }
  .imgTicket {
    display: flex;
    justify-content: center;
    width: 45%;
    img {
      @media #{$md-layout},#{$lg-layout} {
        width: 100%;
      }
    }
    @media #{$xs-layout} {
      display: none;
    }
  }
  p {
    color: var(--neutral-color-50, #cfcfd1);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.125rem;
    margin-top: 4rem;
    @media #{$xs-layout} {
      width: 80%;
    }
  }
  h1 {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    color: white;
    @media #{$xs-layout} {
      font-size: 2rem;
    }
  }
  span {
    background: -webkit-linear-gradient(#413df6, #40b5e8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .btns-home-card {
    width: max-content;
    @media #{$small-mobile} {
      flex-direction: column;
      display: flex;
    }
  }
}
[dir="rtl"] .text-card {
  margin-left: 0rem;
  margin-right: 7.8rem;
}
.create-event {
  width: 198px;
  height: 60px;
  background-color: #413df6;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 16px;
  margin-right: 40px;
  margin-top: 40px;
  @media #{$xs-layout} {
    margin-bottom: 25px;
  }
}
.discover-event {
  border: 0.1rem solid;
  background-color: #171717;
  @media #{$xs-layout} {
    margin-top: 0;
  }
}

.events {
  display: grid;
  justify-content: center;
  text-align: center;
  color: #0f1017;
  background-color: white;
  .events-input {
    height: 3.4594rem;
    display: flex;
    align-items: center;
    img {
      height: 100%;
    }
  }
  input {
    padding: 0.88rem 2.6rem 0.88rem 2.6rem;
    width: 30.75rem;
    height: 100%;
    background: none;
    border: 3px #413df6 solid;
    border-radius: 0.5rem;
    @media #{$xs-layout} {
      margin-left: auto;
      margin-right: auto;
      width: 78%;
    }
  }
  ::placeholder {
    color: #aaa9a9;
  }
  .menu-label {
    display: flex;
    align-items: center;
    color: #413df6;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
    cursor: pointer;
  }
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  align-items: center;
  color: #413df6 !important;
  font-family: Poppins;
  line-height: normal;
  margin: 0;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 9rem !important;
}
.search {
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
}
.Mui-focused {
  border: none !important;
}
.css-i4bv87-MuiSvgIcon-root {
  color: #413df6 !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  @media #{$md-layout} {
    font-size: 1.5rem !important;
  }
  @media #{$xs-layout} {
    font-size: 1.5rem !important;
  }
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: #413df6 !important;
  font-family: Poppins !important;
  font-size: 1rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 2.76rem !important;
}
.all-events {
  background-color: white;
  padding-top: 4.56rem;
  padding-bottom: 4.75rem;
  padding-right: 6.26rem;
  padding-left: 6.63rem;
  @media #{$md-layout} {
    padding: 3rem 2rem;
  }
  @media #{$xs-layout} {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0.5rem;
  }
  .popular {
    color: #0f1017;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.76rem;
    cursor: pointer;
    @media #{$md-layout} {
      font-size: 1.5rem;
    }
    @media #{$xs-layout} {
      font-size: 1.15rem;
    }
  }
  .container {
    flex-direction: row;
    display: flex;
    align-items: center;
    @media #{$xs-layout} {
      justify-content: center;
    }
    .coventry {
      color: #413df6;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.76rem;
      cursor: pointer;
      @media #{$md-layout} {
        font-size: 1.5rem;
      }
      @media #{$xs-layout} {
        font-size: 1.5rem;
      }
    }
    img {
      margin-right: 0.88rem;
      margin-left: 1.5rem;
    }
  }
  .date {
    border-radius: 1.25rem;
    background: rgba(65, 61, 246, 0.08);
    display: flex;
    padding: 0px 1.125rem;
    justify-content: center;
    align-items: center;
    width: 12rem;
    height: 4.5rem;
    @media #{$xs-layout} {
      width: 10.5rem;
    }
    p {
      display: flex;
      align-items: center;
      color: #413df6;
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      cursor: pointer;
      img {
        margin-left: 0.5rem;
      }
      .arrowDown {
        stroke-width: 2px;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .css-ece9u5 {
      min-width: 163px !important;
      flex-direction: row !important;
      display: flex !important;
      align-items: center !important;
    }
  }
}
.css-ece9u5 {
  min-width: 6rem !important;
  flex-direction: row-reverse !important;
  display: flex !important;
  align-items: center !important;
}
.events-cards {
  background-color: white;
  .col,
  .col-4 {
    display: flex;
    @media #{$md-layout} {
      justify-content: center;
    }
    @media #{$xs-layout} {
      justify-content: center;
    }
    .like {
      color: rgba(255, 255, 255, 0.8);
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.9375rem;
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
    .statistics {
      justify-content: space-between;
      display: flex;
      margin-bottom: 1rem;
      img {
        margin-right: 0.5rem;
      }
      .directions {
        color: #188aec;
        text-decoration: underline;
      }
      .book-now {
        background-color: #413df6;
        color: white;
        min-width: 11rem;
        height: 3.75rem;
      }
      .more-details {
        background-color: #171717;
        border-radius: 0.5rem;
        border: 2px solid #fff;
        img {
          margin-left: 0.5rem;
        }
      }
      .location {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .btns {
      // gap: 1rem;
      @media #{$small-mobile} {
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
  .card {
    background-color: #171717;
    border-radius: 0.75rem;
    margin-top: 4rem;
    min-width: 23.25rem;
    max-width: 23.25rem;
    min-height: 32rem;
    max-height: 36rem;
    border: none;
  }
  .book-now {
    background-color: #413df6;
    color: white;
    min-width: 11rem;
    height: 3.75rem;
  }
  .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #188aec;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
  }
  .card-text {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .best-selling {
    color: #0f1017;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
  }
  .view-more {
    color: #413df6;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .load-more {
    display: flex;
    width: 11rem;
    padding: 1.125rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    background: rgba(65, 61, 246, 0.08);
    margin-top: 3.25rem;
    cursor: pointer;
    span {
      color: #413df6;
      text-align: center;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 1.125rem;
      height: 0.5625rem;
    }
  }
  .no-search-results {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
  }
}
.features {
  margin-top: 3.63rem;
  display: flex;
  justify-content: center;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1.75rem;
  .title {
    color: #3bb6ff;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.0625rem;
    text-transform: uppercase;
    margin: 0;
  }
  .description {
    color: #fff;
    font-family: Poppins;
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2.7625rem;
    margin-bottom: 1.75rem;
    @media #{$xs-layout} {
      justify-content: center;
      font-size: 1.125rem;
      font-weight: 400;
    }
  }
}
.steps-container {
  justify-content: center;
  gap: 4.85rem;
  margin-bottom: 11.14rem;
  @media #{$md-layout} {
    justify-content: center;
  }
  @media #{$xs-layout} {
    justify-content: center;
  }
  .step-one {
    width: 19.02638rem;
    height: 25.54988rem;
    background-color: #171717;
    background-image: url("../img/stepOneBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    .content {
      margin-top: 9.16rem;
      padding: 1.43rem;
      position: relative;
      p {
        margin: 0;
      }
      .title {
        color: #fff;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 1.7rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.375rem;
        margin-bottom: 1.07rem;
      }
      .description {
        color: var(--general-light, #fff);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        opacity: 0.7;
      }
      button {
        margin-right: 0;
        margin-top: 2.16rem;
        background: none;
      }
    }
    .number-one {
      position: absolute;
      color: #fff;
      font-family: Poppins;
      font-size: 4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      top: -3.4375rem;
      left: -0.875rem;
    }
  }
  .step-two {
    background: none;
    border-radius: 0.5rem;
    border: 2px solid #fff;
    .content {
      margin-top: 1.3rem;
      .stepTwoImg {
        margin-bottom: 2rem;
      }
      .title {
        width: 15.5rem;
      }
    }
  }
  .step-three {
    border: none;
    .step-three-img {
      width: 7.10206rem;
      height: 4.34144rem;
      margin-top: 1.3rem;
      margin-bottom: 1.7rem;
    }
    button {
      background: #413df6 !important;
      margin-top: 1.31rem;
      border: none;
    }
    .title {
      width: 9.5rem;
    }
    .number-three {
      position: absolute;
      color: #fff;
      font-family: Poppins;
      font-size: 4rem;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      top: -3.4375rem;
      left: 1.5rem;
    }
  }
}
.steps {
  background-image: conic-gradient(
    #171717 0deg,
    #171717 86deg,
    white 89deg,
    white 180deg,
    white 180deg,
    white 267deg,
    #171717 267deg
  );
}
.footer {
  .col {
    max-width: 10rem;
  }
  .section {
    color: #fff;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
  }
  .content {
    color: var(--neutral-color-500, #57585d);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    cursor: pointer;
  }
}
.footer-container {
  border-top: 0.1rem rgba(207, 207, 209, 0.5) solid;
  padding: 0;
  align-items: center;
  padding-top: 1.3rem;
  padding-bottom: 3.9rem;
  @media #{$xs-layout} {
    margin: 0 !important;
  }
  .last-layer {
    img {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      margin-top: 0.2rem;
    }
  }
  .content {
    color: var(--neutral-color-500, #57585d);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    cursor: pointer;
  }
  .col {
    min-width: fit-content;
  }
  .lang {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: context-menu;
    &img {
      width: 1.125rem;
      height: 0.75rem;
      align-items: center;
      margin-right: 0.5rem;
    }
  }
  .imgs {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    //img{
    //  cursor: pointer;
    //}
    @media #{$xs-layout} {
      display: block;
    }
  }
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 0px !important;
}
li {
  padding: 0px 10px;
  &:last-child {
    display: flex;
    align-items: center;
    padding-left: 20px;
    cursor: pointer;
    img {
      width: 1.1rem;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      margin-top: 0.2rem;
    }
  }
}
//.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
//  display: none !important;
//}
[dir="rtl"].css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  left: 0 !important;
  right: 1.5rem !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  width: 10rem !important;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  right: 3rem !important;
}
[dir="rtl"].css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  right: 2.3rem !important;
}
.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: #413df6 !important;
  overflow: inherit !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  margin-top: 2.5rem !important;
  color: #413df6 !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}
.menu-categories {
  margin-top: 0.5rem;
}

.css-13maa2j,
.css-p0rm37 {
  color: #413df6 !important;
  overflow: inherit !important;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
}
.css-vubbuv {
  color: #413df6 !important;
}
.catagory {
  width: 100%;
  .ant-select-selector,
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #413df6 !important;
    font-family: Poppins !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }
  .ant-select-clear {
    font-size: 20px !important;
    height: fit-content !important;
    width: fit-content !important;
    border-radius: 6px;
  }
}
.catagory-location {
  .ant-select-selector,
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #413df6 !important;
    font-family: Poppins !important;
    font-size: 2rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    margin-right: 1rem !important;
  }
  .ant-select-clear {
    font-size: 31px !important;
    height: fit-content !important;
    width: fit-content !important;
  }
}
.date-for-events-home {
  width: 100%;
  input,
  input::placeholder {
    color: #413df6 !important;
    font-family: Poppins !important;
    font-size: 0.875rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
  }
  .ant-picker-suffix {
    color: #413df6 !important;
    font-size: 1rem !important;
  }
  .ant-picker-clear {
    font-size: 1rem !important;
    height: fit-content !important;
    width: fit-content !important;
    border-radius: 20%;
  }
}
.card-img-top {
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}
