.my-events {
  padding: 0px 30px;
  .user {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    padding: 0.7rem 5rem;
    padding-right: 40px;
  }
  .events-title {
    margin: 0 1rem;
    color: #fff;
    font-family: Poppins;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
  }
  .all-event {
    ul {
      list-style-type: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 2.88rem;
      @media #{$small-mobile} {
        flex-direction: column;
      }
      li {
        align-items: center;
        gap: 1.12rem;
        display: flex;
        justify-content: center;
        padding: 1.44rem;
        cursor: pointer;
        opacity: 0.5;
        color: #fff;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.09375rem;
        width: auto;
        height: 4rem;
        @media #{$xs-layout} {
          width: auto;
        }
      }
      img {
        height: 1.125rem;
        margin: 0;
      }
    }
  }
  .tab-events-show {
    border-radius: 0.75rem 0.75rem 0rem 0rem;
    border-bottom: 2px solid #188aec;
    background: rgba(65, 61, 246, 0.05);
    opacity: 1 !important;
  }
  .edit {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    img {
      margin: 0;
    }
  }
  .no-event-to-show {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10%;
    p {
      color: #fff;
      font-family: Poppins;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem;
    }
  }
}
