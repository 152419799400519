@font-face {
  font-family: Poppins;
  src: local("Poppins-Regular"),
    url(../fonts/Poppins-Regular.otf) format("OpenType ");
}

#root {
  height: 100vh;
}
#error-page {
  height: 100%;
  background-color: red;
  margin: auto;
  width: 50%;
}
body {
  color: $white;
  font-family: "Poppins" !important;
  background-color: $bg-black !important;
}
.xPay-logo {
  background-image: url("../img/imgWithLogo.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 50%;
  background-position: center center;
  @media #{$xs-layout},#{$md-layout} {
    display: none;
  }
}
.leftBall {
  width: 1.3rem;
  height: 2.625rem;
  border: #615f5f 0.1rem solid;
  position: absolute;
  left: -0.1rem;
  background-color: $bg-black;
  border-bottom-right-radius: 3rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-color: $black;
}
.rightBall {
  width: 1.3rem;
  height: 2.625rem;
  border: #615f5f 0.1rem solid;
  position: absolute;
  right: -0.1rem;
  background-color: $bg-black;
  border-bottom-left-radius: 3rem;
  border-top-left-radius: 3rem;
  border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-color: $black;
}
.balls {
  flex-direction: row;
  display: flex;
  padding-left: 5.1rem;
  padding-right: 5.1rem;
  margin-top: 2.5rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  justify-content: space-between;
  gap: 9px;
  @media #{$xs-layout},#{$md-layout} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.ball {
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 100%;
  border: #615f5f 0.1rem solid;
}
.marginHeaderAndFooter {
  margin-left: 5rem;
  margin-right: 5rem;
  @media #{$xs-layout},#{$md-layout} {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.css-1wc848c-MuiFormHelperText-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
[dir="rtl"] .css-1wc848c-MuiFormHelperText-root {
  text-align: right !important;
}
.response-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  @media #{$xs-layout} {
    padding: 0;
  }

  .btn-wrapper {
    margin: 0;
    position: static;
    width: 60%;

    @media #{$small-mobile} {
      width: 80%;
    }
  }
  p {
    font-size: 22px;
    font-weight: bold;
    color: $white;
    text-align: center;
    margin: 30px;
    @media #{$small-mobile} {
      font-size: 18px;
      margin: 30px 0px;
    }
    @media #{$sm-layout} {
      margin: 30px 0px;
    }
    span {
      @media #{$small-mobile} {
        display: block;
      }
    }
  }
  a {
    width: 100%;
  }
  button {
    height: 60px;
    background-color: $bg-blue;
    border-radius: 12px;
    border: none;
    width: 100%;
    color: $white;
    font-size: 22px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 40px;
    opacity: 1;
    @media #{$small-mobile} {
      font-size: 18px;
    }
  }
}
.modal-content {
  background-color: $bg-black;
}
.dropdown-menu[data-bs-popper] {
  right: 0;
  left: auto;
}
.pointer {
  cursor: pointer;
}
.input-file {
  z-index: 2;
  position: absolute;
}
.uploud-file-btn {
  position: absolute;
  right: 0.38rem;
  bottom: 0.31rem;
  width: 6.68rem;
  height: 2.2rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  color: #fff;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  border-radius: 0.5rem;
  background: rgba(65, 61, 246, 0.4);
  border: none;
}
.ant-picker-input > input {
  color: white !important;
}
.ant-picker-input > input::placeholder {
  color: rgba(255, 255, 255, 0.3) !important;
}
.ant-picker {
  border: none !important;
}
.w-100 {
  width: 100%;
}

.event-placeholder-img {
  height: 354px;
  background-color: #cecece;
  content: url(../img/placeholder_view_vector.svg);
  border-top-right-radius: 0.75rem;
  border-top-left-radius: 0.75rem;
}
.decoration-none {
  text-decoration: none;
  color: inherit;
}
.camera {
  display: flex;
  align-items: center;
  gap: 2rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
  p {
    margin: 0;
  }
  img {
    width: 3rem !important;
    cursor: pointer !important;
  }
}
.camera-img-user {
  display: flex;
  align-items: center;
  gap: 2rem;
  position: absolute;
  right: 0rem;
  top: 5rem;
  img {
    width: 3rem !important;
    border: none !important;
    cursor: pointer !important;
    @media #{$xs-layout} {
      width: 2rem !important;
    }
  }
  @media #{$xs-layout} {
    top: 2rem;
    right: -1rem;
  }
}
.card-settings {
  background: rgba(65, 61, 246, 0.05);
  border-radius: 1.25rem;
  height: fit-content;
  padding: 3rem;
  p {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  .e-mail {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .change-password {
    color: #413df6;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.checkout {
  padding-left: 3.38rem;
  margin-bottom: 4rem;
  .ticket-info {
    margin-top: 1.3rem;
    margin-bottom: 2rem;
    row-gap: 1.5rem;
    border-radius: 0.5rem;
    background: rgba(92, 187, 145, 0.07);
    padding-top: 1.5rem;
    padding-bottom: 1.62rem;
    justify-content: center;
    .row {
      row-gap: 1rem;
    }
    label {
      background-color: #1c221e;
    }
  }
  .ticket-vip {
    background: rgba(217, 217, 217, 0.04);
  }
  .ticket-super-vip {
    background: rgba(89, 117, 217, 0.07);
  }
  .ticket-num {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
  }
  .ticket-type {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .ticket-num-three {
    color: #5975d9;
  }
}
.ticket-late {
  color: #5975d9;
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
.order {
  border-radius: 1.25rem;
  background: rgba(65, 61, 246, 0.05);
  padding: 2.88rem;
  .head-text-order {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.8125rem;
    text-align: start;
    margin: 0;
  }
  .early-order {
    color: #5cbb91;
    font-family: Poppins;
    font-size: 0.525rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .egp-order {
    color: #fff;
    font-family: Poppins;
    font-size: 0.865rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25rem;
    margin: 0;
  }
  .price-order {
    color: #fff;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
    margin: 0;
  }
  .remove-order {
    color: #188aec;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.8125rem;
    text-decoration-line: underline;
  }
}
.img-btn-order {
  background-image: url(../img/bgButton.svg);
  height: 3.75rem;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.user-name-edit {
  padding: 0;
  font-size: 1.5rem;
  line-height: 2.25rem;
  .ant-input {
    color: #fff;
    font-weight: 700;
  }
  .ant-input[disabled] {
    cursor: auto;
  }
}
.social-setting {
  background: transparent;
  color: white;
}
.social-setting::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.menu {
  li {
    padding: 0px;
    &:last-child {
      display: inline;
      align-items: center;
      padding-left: 0px;
      cursor: pointer;
      img {
        width: 1.1rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        margin-top: 0.2rem;
      }
    }
  }
}
.btn-close {
  filter: brightness(0) invert(1);
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
:where(.css-dev-only-do-not-override-pr0fja).ant-popover .ant-popover-inner {
  background-color: black !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-popconfirm
  .ant-popconfirm-message
  .ant-popconfirm-title {
  color: white !important;
}
:where(.css-dev-only-do-not-override-pr0fja).ant-popconfirm
  .ant-popconfirm-message
  .ant-popconfirm-description {
  color: white !important;
}
