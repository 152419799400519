.viewAndLove {
  .box {
    min-width: 8.3rem;
    height: 3.4rem;
    border-radius: 0.75rem;
    background: rgba(65, 61, 246, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.cardContent {
  min-height: 6.25rem;
  display: flex;
  justify-content: center;
  border-radius: 1.25rem;
  background: rgba(65, 61, 246, 0.05);
  padding-left: 0.5rem;
  @media #{$xs-layout} {
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
  }

  p {
    margin: 0;
    color: #fff;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
  }
  .desc {
    color: #919eab;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    white-space: pre-line;
  }
  .date {
    font-size: 1.25rem !important;
  }
  .profile-img {
    width: 65px;
    height: 65px;
    border-radius: 100%;
  }
  a {
    text-decoration: none;
  }
}

.about-event {
  margin-left: 2rem;
  margin-top: 3.75rem;
  .title-about-event {
    margin-bottom: 2.37rem;
    color: #fff;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2rem;
  }
  .desc {
    color: #919eab;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    white-space: pre-line;
  }
  .font-bold {
    font-weight: 700;
    margin-top: 1.81rem;
  }
  .tags {
    flex-direction: row;
    display: flex;
    gap: 1rem;
    margin-top: 0.75rem;
    .tag {
      display: flex;
      padding: 0.33rem 0.75rem;
      border-radius: 1rem;
      background: rgba(65, 61, 246, 0.14);
    }
  }
}
.viewContent {
  @media #{$xs-layout} {
    flex-direction: column;
  }
  .content {
    gap: 1.25rem;
    display: flex;
    align-items: center;
    width: 95%;
    .organizedBy {
      color: rgba(255, 255, 255, 0.8);
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.8125rem;
      margin-top: 0.5rem;
    }
    .userName {
      font-size: 1.25rem;
      color: white;
      white-space: nowrap;
      cursor: pointer;
    }
    .viewBtn {
      display: flex;
      width: 6.875rem;
      height: 2.4375rem;
      padding: 0.75rem 1.4375rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      border: 1px solid #fff;
      color: $white;
    }
    .date {
      font-size: 1.5rem;
      line-height: 1.2;
      @media #{$xs-layout} {
        font-size: 1.25rem;
      }
    }
  }
}
.cardTickets {
  border-radius: 1.25rem;
  background: rgba(65, 61, 246, 0.05);
  margin-bottom: 3.5rem;
  .tickets {
    display: flex;
    align-items: center;
    margin-top: 2.62rem;
    margin-bottom: 0.38rem;
    padding-left: 2.3rem;
  }
  .date {
    color: #fff;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .ticketCard,
  .totalTickets {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .ticket {
    background: linear-gradient(
        25deg,
        rgba(191, 191, 191, 0.06) 5.98%,
        rgba(0, 0, 0, 0) 66.28%
      ),
      rgba(0, 0, 0, 0.14);
    border: 0.01rem solid rgba(175, 175, 175, 0.5);
    //border-image: conic-gradient(rgba(191, 191, 191, 0.06), rgba(0, 0, 0, 0.00)) 1;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    min-height: 7rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.12rem;
    @media #{$xs-layout} {
      flex-direction: column;
      padding: 0.5rem;
      justify-content: space-between;
    }
    .discount {
      position: absolute;
      bottom: 1.2rem;
      width: max-content;
    }
    .minusAndPlus {
      align-items: center;
      flex-direction: row;
      display: flex;
      gap: 0.8rem;
      justify-content: flex-end;
      img {
        cursor: pointer;
      }
    }
    .vip {
      color: #f8b434;
    }
    .superVip {
      color: #5cbb91;
    }
    .textBox {
      width: max-content;
      display: block;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .ticketType {
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 0.5rem;
  }
  .totalTickets {
    justify-content: space-between;
    display: flex;
    margin-bottom: 1.37rem;
  }
  .proceedBtn {
    background-image: url("../img/bgBlue.svg");
    background-size: 100% 100%;
    height: 3.75rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    .Btn {
      width: 95%;
      height: 100%;
      background: transparent;
      border: none;
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem;
    }
  }
}
.viewBtn {
  display: flex;
  width: 6.875rem;
  height: 2.4375rem;
  padding: 0.75rem 1.4375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #fff;
  color: #fff;
}
.event-organizer {
  .img-event {
    height: 14.5rem;
    background-color: #697480;
    margin: 1rem;
    margin-bottom: 0rem;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
    }
  }
  .all-events-organizer {
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    background: rgba(65, 61, 246, 0.05);
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    height: 4rem;
    gap: 2.78rem;
    .organizer {
      position: absolute;
      flex-direction: row;
      display: flex;
      gap: 1.6rem;
      bottom: 2.5rem;
      left: 1.63rem;
      @media #{$xs-layout} {
        margin-bottom: 2rem;
      }
      // @media #{$extra-small-mobile} {
      //   flex-direction: column;
      // }
      .img-organizer {
        width: 7.75rem;
        height: 7.75rem;
        @media #{$xs-layout} {
          width: 4rem;
          height: 4rem;
        }
        img {
          width: 100%;
          height: 100%;
          border: 0.2rem solid;
          border-radius: 100%;
        }
      }
      .name-organizer {
        flex-direction: column;
        display: flex;
        justify-content: center;
        .name {
          color: #fff;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: 700;
          line-height: 2.25rem;
        }
        .position {
          color: #fff;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          opacity: 0.72;
        }
      }
    }
  }
  .tabs-events {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    li {
      list-style: none;
      align-items: center;
      cursor: default;
      display: flex;
      gap: 0.75rem;
      padding: 0;
      img {
        height: 1.125rem;
        margin: 0 !important;
      }
    }
    .tab-events {
      font-size: 0.875rem;
      color: #fff;
      font-weight: 600;
      font-style: normal;
      line-height: 1.09375rem;
      cursor: pointer;
      opacity: 0.4;
    }
    .tab-events-active {
      opacity: 1;
    }
  }
  .cardTickets {
    min-height: 29.875rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    p {
      font-size: 1.25rem;
      margin-top: 2.37rem;
      text-align: center;
    }
  }
  .location-event {
    margin-top: 1rem;
    img {
      margin-right: 0.68rem;
      width: 1.0065rem;
      height: 1.3125rem;
    }
    span {
      font-weight: 600 !important;
      color: white !important;
    }
  }
}
.cards-center {
  @media #{$xs-layout,$md-layout} {
    justify-content: center;
  }
}
.btns {
  @media #{$small-mobile} {
    flex-direction: column;
    gap: 1rem;
  }
}
.cards {
  background: rgba(65, 61, 246, 0.05);
  max-width: 21.0625rem;
  padding: 0;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 30px;
  .img-card {
    height: 300px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .col {
    display: flex;
    @media #{$md-layout} {
      justify-content: center;
    }
    @media #{$xs-layout} {
      justify-content: center;
    }
    .like {
      color: rgba(255, 255, 255, 0.8);
      font-family: Poppins;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.9375rem;
      align-items: center;
      display: flex;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .card-body {
    padding: 1.8rem;
  }
  .statistics {
    justify-content: space-between;
    display: flex;
    margin-bottom: 1rem;
    img {
      margin-right: 0.5rem;
    }
    .directions {
      color: #188aec;
      text-decoration: underline;
    }
    .bookNow {
      background-color: #413df6;
      color: white;
      width: 8.3rem;
      height: 3rem;
      font-size: 1rem;
      padding: 0.3rem;
      @media #{$small-mobile} {
        width: 100%;
      }
    }
    .more-details {
      background-color: #171717;
      border-radius: 0.5rem;
      border: 2px solid #fff;
      img {
        margin-left: 0.2rem;
        margin-right: 0rem;
      }
    }
    .location {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  .card {
    background-color: #171717;
    border-radius: 0.75rem;
    margin-top: 4rem;
    max-width: 25.25rem;
  }
  .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #188aec;
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
  }
  .card-text {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .best-selling {
    color: #0f1017;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
  }
  .view-more {
    color: #413df6;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .load-more {
    display: flex;
    width: 11rem;
    padding: 1.125rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    background: rgba(65, 61, 246, 0.08);
    margin-top: 3.25rem;
    cursor: pointer;
    span {
      color: #413df6;
      text-align: center;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 1.125rem;
      height: 0.5625rem;
    }
  }
}
.events-categories {
  width: 100%;
  .ant-select-selector,
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    color: #188aec !important;
    font-size: 1.375rem !important;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
  }
  .ant-select-clear {
    font-size: 31px !important;
    height: fit-content !important;
    width: fit-content !important;
  }
  .ant-select-arrow {
    font-size: 1rem;
    color: #188aec;
  }
}
.date-for-events {
  max-width: 11rem;
  margin-right: 4rem;
  input,
  input::placeholder {
    color: #188aec !important;
    font-size: 1.375rem !important;
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 600 !important;
  }
  .ant-picker-suffix {
    color: #188aec !important;
    font-size: 1rem !important;
  }
  .ant-picker-clear {
    font-size: 1rem !important;
    height: fit-content !important;
    width: fit-content !important;
    border-radius: 20%;
  }
}
.result-for {
  color: rgba(255, 255, 255, 0.8);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
  span {
    color: #188aec;
    font-weight: 500;
  }
}
.no-result {
  border-radius: 1.25rem;
  background: rgba(65, 61, 246, 0.05);
  max-height: 42.8125rem;
  min-height: 30rem;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    margin-bottom: 1rem;
  }
}
.menu-filter {
  background: transparent;
  .ant-menu-sub.ant-menu-inline {
    background: transparent !important;
  }
  .ant-menu-submenu-title > .ant-menu-title-content {
    color: #188aec;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
  }
  label {
    width: 100%;
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75rem;
    margin-bottom: 0.75rem;
  }
  .ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0 0 2rem 0 !important;
  }
  .ant-checkbox .ant-checkbox-inner {
    background-color: transparent;
  }
}
