.border-li {
  border-bottom: 2px solid #188aec;
  height: 100%;
}
.cards-events {
  background: rgba(65, 61, 246, 0.05);
  max-width: 15.625rem;
  padding: 0;
  border-radius: 1rem;
  overflow: hidden;
  .img-card {
    // height: 11.09rem;
    height: 150px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .col {
    display: flex;
    @media #{$md-layout} {
      justify-content: center;
    }
    @media #{$xs-layout} {
      justify-content: center;
    }
    .like {
      color: rgba(255, 255, 255, 0.5);
      font-family: Poppins;
      font-size: 0.675rem;
      font-style: normal;
      font-weight: 400;
      line-height: 0.9375rem;
      align-items: center;
      display: flex;
      margin-bottom: 0;
    }
  }
  .card-body {
    padding: 1rem;
  }
  .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #188aec;
    font-family: Poppins;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
  }
  .card-text {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .best-selling {
    color: #0f1017;
    font-family: Poppins;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
  }
  .view-more {
    color: #413df6;
    font-family: Poppins;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 138%;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .load-more {
    display: flex;
    width: 11rem;
    padding: 1.125rem;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.5rem;
    background: rgba(65, 61, 246, 0.08);
    margin-top: 3.25rem;
    cursor: pointer;
    span {
      color: #413df6;
      text-align: center;
      font-family: Poppins;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 1.125rem;
      height: 0.5625rem;
    }
  }
}
.btn-add-admin,
.btn-add-admin:hover {
  width: 100%;
  margin-bottom: 1.13rem;
  padding: 0.3125rem 0.875rem;
  border-radius: 0.375rem;
  background: rgba(65, 61, 246, 0.3);
  color: #fff;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}
.edit-organization {
  background-color: transparent;
  border-radius: 0.5rem;
  border: 2px solid #919eab;
  padding: 0.75rem;
  display: flex;
  gap: 0.7rem;
  align-items: center;
  justify-content: center;
  color: #919eab;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  min-width: 90%;
  margin-top: 2rem;
  @media #{$small-mobile} {
    width: 100%;
  }
  img {
    margin-left: 0.2rem;
    margin-right: 0rem;
  }
}

.create-title {
  color: #fff;
  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  gap: 1.3rem;
  margin: 0;
  @media #{$xs-layout} {
    font-size: 1rem;
    width: max-content;
  }
}
.create-subtitle {
  color: #fff;
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin: 0;
}
.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  img {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.event-organizer {
  .statistics {
    justify-content: space-between;
    display: flex;
    margin-bottom: 1rem;
    gap: 0.75rem;
    a {
      text-decoration: none;
    }
    img {
      margin-right: 0.5rem;
    }
    .directions {
      color: #188aec;
      text-decoration: underline;
    }
    .bookNow {
      background-color: #413df6;
      color: white;
      min-width: 6rem;
      font-size: 0.875rem;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.3rem;
      border: none;
      border-radius: 0.5rem;
      width: 10.5rem;
      @media #{$small-mobile} {
        width: 100%;
      }
    }
    .more-details {
      background-color: #171717;
      border-radius: 0.5rem;
      border: 2px solid #fff;
      img {
        margin-left: 0.2rem;
        margin-right: 0rem;
      }
    }
    .date {
      color: rgba(255, 255, 255);
      font-size: 0.75rem;
    }
  }
}
.statistics {
  justify-content: space-between;
  display: flex;
  margin-bottom: 1rem;
  gap: 0.75rem;
  img {
    margin-right: 0.5rem;
  }
  a {
    text-decoration: none;
  }
  .directions {
    color: #188aec;
    text-decoration: underline;
  }
  .bookNow {
    background-color: #413df6;
    color: white;
    min-width: 6rem;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    border: none;
    border-radius: 0.5rem;
    width: 10.5rem;
    @media #{$small-mobile} {
      width: 100%;
    }
  }
  .more-details {
    background-color: #171717;
    border-radius: 0.5rem;
    border: 2px solid #fff;
    img {
      margin-left: 0.2rem;
      margin-right: 0rem;
    }
  }
  .date {
    color: rgba(255, 255, 255);
    font-size: 0.75rem;
  }
}
.width-colum {
  width: 75%;
}
