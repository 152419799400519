.drawer {
  background: linear-gradient(
    to bottom,
    #202035,
    #202035,
    #202035,
    #47808d
  ) !important;
  transition-duration: 500ms !important;
  overflow: auto;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  width: 16rem;
  padding: 0rem;
  @media #{$xs-layout} {
    width: 100%;
  }
  .logo {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    margin-bottom: 4rem;
    padding: 0rem 1.5rem;
  }
  .logoSmall {
    margin-top: 0rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
    justify-content: center;
    display: flex;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4.375rem;
    padding: 0rem 1.5rem;
    .item-img {
      margin-right: 1.25rem;
    }
    img {
      object-fit: none;
    }
  }
  .item:hover {
    background: #d9d9d90f;
  }
  .item-active {
    background: #d9d9d90f;
    border-left: 0.2rem solid #188aec;
  }
  .item-title {
    margin: 0;
  }
  ul {
    margin-top: 1rem;
    list-style-type: none;
    li {
      padding-left: 0.1rem;
      margin-bottom: 0.5rem;
      list-style: none;
      display: flex;
      gap: 0.5rem;
      content: "•";
      cursor: pointer;
    }
    li:before {
      content: "•";
    }
  }
  .display-none {
    display: none;
  }
}
.drawer-in-small {
  @media #{$xs-layout} {
    display: none;
  }
}
.overlayStyle {
  background: transparent !important;
}
.create-event-container {
  .user {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0.7rem;
  }
  .create-event-stepper {
    display: flex;
    flex-direction: row;
    margin-top: 1.37rem;
    .create {
      img {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    h3 {
      margin: 0;
      color: #fff;
      font-family: Poppins;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem;
      width: max-content;
    }
  }
}
.container-stepper {
  .row-stepper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    @media #{$xs-layout} {
      flex-direction: column;
    }
    .cancel-create {
      display: flex;
      justify-content: flex-end;
      @media #{$xs-layout} {
        justify-content: flex-start;
        width: max-content;
      }
    }
  }
}
.form-group {
  border: 1px solid #fff;
  border-radius: 0.5rem;
  position: relative;
  color: #fff;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 3rem;
  padding: 0;
  width: 100%;
  .textarea {
    resize: none;
    overflow: visible;
    height: 7.8125rem;
    padding: 0.88rem 1rem;
    color: #fff;
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.red-border {
  border: 1px solid red;
}
.border-none {
  border: none;
}
.form-group > label {
  position: absolute;
  top: -11px;
  left: 25px;
  background-color: #171717;
  padding: 0 5px;
  color: #fff;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 2;
}
[dir="rtl"] .form-group > label {
  left: 0;
  right: 25px;
  width: fit-content;
}
.form-group > input {
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 100%;
}

.form-control {
  background: transparent !important;
  :focus {
    box-shadow: none !important;
    color: #fff !important;
    border-color: none !important;
  }
}
.form-control:focus {
  color: #fff !important;
}
.form-select {
  background-color: #171717;
  position: relative;
  color: #fff;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0.84rem;
  height: 3rem;
  :focus {
    box-shadow: none !important;
  }
  option {
    height: 10rem;
  }
}
.details-event {
  padding-left: 1rem;
  margin-left: 0;
  .note {
    width: 24.1875rem;
    min-height: 4.5rem;
    border-radius: 0.5rem;
    background: rgba(65, 61, 246, 0.07);
    padding: 0.81rem 0.37rem 0.81rem 0.75rem;
    display: flex;
    align-items: center;
    @media #{$xs-layout} {
      flex-direction: column;
    }
    p {
      color: #fff;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin: 0;
    }
    span {
      color: #188aec;
      font-family: Poppins;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    .form-check-input[type="radio"] {
      background: transparent;
    }
    .form-check-input[type="radio"]:checked {
      background: #188aec;
      border-color: white;
    }
  }
}
.form-check-box {
  margin-bottom: 1rem;
  .form-checkbox {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.01875rem;
    align-items: center;
    display: flex;
  }
  input[type="checkbox"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    color: currentColor;
    width: 1.125rem;
    height: 1.125rem;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    margin-right: 0.88rem;
    display: grid;
    place-content: center;
    background-color: transparent;
  }

  .form-checkbox + .form-checkbox {
    margin-top: 1em;
  }
  input[type="checkbox"]::before {
    content: "";
    width: 0.88rem;
    height: 0.88rem;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    background-color: #188aec;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
}
input[type="file"] {
  border-radius: 0.5rem;
  :hover {
    background: rgba(65, 61, 246, 0.4);
  }
}
input[type="file"]::file-selector-button {
  margin-right: 20px;
  border: none;
  background-color: rgba(65, 61, 246, 0.4);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}
.stepper-next {
  display: flex;
  min-width: 10.5rem;
  height: 3.25rem;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.5rem;
  background: #413df6;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
  float: right;
  margin: 3rem 0rem;
  border-color: #413df6;
  border:none img {
    margin-top: 0.1rem;
  }
}
.stepper-back {
  float: left;
  img {
    rotate: 180deg;
  }
}
.egp {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  color: #fff;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}
.the-table {
  padding: 0;

  .table {
    --bs-table-bg: transparent;
    margin: 0;
    border-radius: 0.5rem;
    border: 0.1rem solid;
    border-collapse: separate;
    .t-head {
      padding: 1rem 2rem 1rem 2rem;
      font-size: 0.6rem;
    }
    td {
      padding: 1rem 2rem 1rem 2rem;
      color: #fff;
      font-family: Poppins;
      font-size: 0.88rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    tr {
      border-bottom: 0.1rem solid;
    }
  }
  .table-striped > tbody > tr:nth-child(2n + 1) > td,
  .table-striped > tbody > tr:nth-child(2n + 1) > th {
    background-color: transparent;
  }
  .usher-name {
    position: absolute;
    top: -17px;
    left: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: #191927;
    color: #fff;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
  }
  .usher-mail {
    position: absolute;
    left: 35%;
    top: -17px;
  }
  .usher-number {
    position: absolute;
    left: 45%;
    top: -17px;
  }
  .tabs-events {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    li {
      list-style: none;
      align-items: center;
      cursor: default;
      display: flex;
      gap: 0.75rem;
      img {
        height: 1.125rem;
        margin: 0 !important;
      }
    }
    .tab-events {
      font-size: 0.875rem;
      color: #fff;
      font-weight: 600;
      font-style: normal;
      line-height: 1.09375rem;
      cursor: pointer;
      opacity: 0.4;
    }
    .tab-events-active {
      opacity: 1;
    }
  }
}
.helper {
  color: red;
}
.column-small {
  @media #{$xs-layout} {
    flex-direction: column;
  }
}
.span-in-stepper {
  span,
  a {
    line-height: 3rem !important;
    // font-size: 1.5rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
  }
  a {
    margin-top: 8px !important;
  }
  div {
    border-width: 2px !important;
  }
}
.check-box {
  display: flex;
  justify-content: flex-end;
  @media #{$xs-layout} {
    justify-content: flex-start;
  }
  @media #{$md-layout} {
    justify-content: flex-start;
  }
}
.stepper-btns {
  display: flex;
  gap: 1.37rem;
  justify-content: flex-end;
  margin: 3rem 1rem;
  button {
    width: fit-content;
    @media #{$xs-layout} {
      width: 100%;
      margin: 0rem;
    }
  }
}
.form-switch {
  .form-check-input {
    height: 1.75rem;
    width: 3rem;
  }
  .form-check-input:checked {
    background-color: #5cbb91;
    border-color: #5cbb91;
    --bs-form-check-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill=#000 stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
  }
  .form-check-input:focus {
    box-shadow: none;
    border: none;
    --bs-form-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill=#000 stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
  }
}
.ushers {
  background: rgba(65, 61, 246, 0.07);
  padding: 2rem;
  overflow: auto;
  p {
    margin: 0;
  }
}
.usher-table {
  border: 0.01rem solid white;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 2rem 0rem;
  .row {
    margin: 2rem 0rem;
    border-bottom: 0.01rem solid white;
  }
}
.assign-text {
  color: #fff;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.96875rem;
  width: 5rem;
  position: absolute;
  right: 20px;
  top: -40px;
}

.form-group-uploadfile {
  align-items: center;
  display: flex;
  padding-left: 1rem;
  color: rgba(255, 255, 255, 0.3);
}
