// text Colors ---------------
$white: #fff;
$blue: #413df6;
$blue-1: #188aec;
$blue-2: #40b5e8;
$black: #171717;
// bg Colors ---------------

$bg-white: #fff;
$bg-black: #171717;
$bg-blue: #413df6;
$bg-blue-2: #40b5e8;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$small-mobile: "only screen and (max-width: 575px)";
$extra-small-mobile: "only screen and (min-width: 280px)and (max-width: 360px)";
$extra-small-mobile: "only screen and (max-width: 479px)";
$desktop-layout: "only screen and (min-width: 992px)";

.card {
  background-color: $bg-black;
}
