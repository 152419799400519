.camera {
  display: flex;
  align-items: center;
  gap: 2rem;
  position: absolute;
  right: 2rem;
  top: 2rem;
  p {
    margin: 0;
  }
  img {
    width: 3rem !important;
    cursor: pointer !important;
  }
}
.camera-img-user {
  display: flex;
  align-items: center;
  gap: 2rem;
  position: absolute;
  right: 0rem;
  top: 5rem;
  img {
    width: 3rem !important;
    border: none !important;
    cursor: pointer !important;
    @media #{$xs-layout} {
      width: 2rem !important;
    }
  }
  @media #{$xs-layout} {
    top: 2rem;
    right: -1rem;
  }
}
.card-settings {
  background: rgba(65, 61, 246, 0.05);
  border-radius: 1.25rem;
  height: fit-content;
  padding: 3rem;
  p {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }
  .e-mail {
    font-size: 1.25rem;
    font-weight: 600;
  }
  .change-password {
    color: #413df6;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.checkout {
  padding-left: 3.38rem;
  margin-bottom: 4rem;
  .ticket-info {
    margin-top: 1.3rem;
    margin-bottom: 2rem;
    row-gap: 1.5rem;
    border-radius: 0.5rem;
    background: rgba(92, 187, 145, 0.07);
    padding-top: 1.5rem;
    padding-bottom: 1.62rem;
    justify-content: center;
    .row {
      row-gap: 1rem;
    }
    label {
      background-color: #1c221e;
    }
  }
  .ticket-vip {
    background: rgba(217, 217, 217, 0.04);
  }
  .ticket-super-vip {
    background: rgba(89, 117, 217, 0.07);
  }
  .ticket-num {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.9375rem;
  }
  .ticket-type {
    text-transform: capitalize;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .ticket-num-three {
    color: #5975d9;
  }
}
.ticket-late {
  color: #5975d9;
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}
.order {
  border-radius: 1.25rem;
  background: rgba(65, 61, 246, 0.05);
  padding: 2.88rem;
  .head-text-order {
    color: #fff;
    font-family: Poppins;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.8125rem;
    text-align: start;
    margin: 0;
  }
  .early-order {
    color: #5cbb91;
    font-family: Poppins;
    font-size: 0.525rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
  }
  .egp-order {
    color: #fff;
    font-family: Poppins;
    font-size: 0.865rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.25rem;
    margin: 0;
  }
  .price-order {
    color: #fff;
    font-family: Poppins;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.8rem;
    text-align: center;
    display: block;
    margin: 0;
  }
  .remove-order {
    color: #188aec;
    font-family: Poppins;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.8125rem;
    text-decoration-line: underline;
  }
}
.img-btn-order {
  background-image: url(../img/bgButton.svg);
  height: 3.75rem;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  position: static;
}
