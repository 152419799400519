.form-outer-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0rem;
  @media #{$xs-layout} {
    margin-top: 5rem;
  }
}
.form-wrapper {
  padding: 2rem 0;
  background: linear-gradient(
      128deg,
      rgba(191, 191, 191, 0.07) -1.02%,
      rgba(0, 0, 0, 0) 39.28%
    ),
    rgba(0, 0, 0, 0.2);
  border: #615f5f 0.1rem solid;
  border-radius: 1rem;
  max-width: 30.625rem;
  min-width: 17rem;
  @media #{$xs-layout} {
    margin-left: 2rem;
    margin-right: 2rem;
    width: auto;
  }
}
.form-card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 75%;
  margin: 0px auto;
  input {
    padding: 0.88rem;
    background: none;
    border-radius: 0.5rem;
    color: $white;
    @media #{$xs-layout} {
      margin: 0;
      width: 88%;
    }
    &::placeholder {
      color: $white;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .passwordInput {
    position: relative;
    @media #{$xs-layout} {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    @media #{$xs-layout} {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    input {
      align-items: center;
      display: flex;
    }
    .input-icon {
      position: absolute;
      top: 0.875rem;
      right: 1.5rem;
      cursor: pointer;
    }
  }
}
[dir="rtl"] .passwordInput .input-icon {
  right: 85%;
  @media #{$small-mobile} {
    right: 80%;
  }
}

.check-box-wrapper {
  color: $white;
  align-items: center;
  display: flex;
  @media #{$xs-layout} {
    font-size: 13px;
  }
  a {
    margin-left: 3px;
    color: $white;
  }
  input {
    margin-right: 10px;
    background-color: $bg-black;
    width: 1.125rem;
    height: 1.125rem;
    border: 1px solid $white;
    border-radius: 0.125rem;
    outline: none;
    transition-duration: 0.3s;
    background-color: $bg-black;
    cursor: pointer;
    input:checked {
      border: 1px solid $white;
      background-color: $bg-blue;
    }
  }
}
[dir="rtl"] .check-box-wrapper input {
  margin-left: 10px;
  margin-right: 0px;
}
[dir="rtl"] .check-box-wrapper a {
  margin-left: 0px;
  margin-right: 3px;
}
.btn-submit {
  display: flex;
  width: 70%;
  height: 5.1875rem;
  padding: 0.875rem 0.625rem;
  justify-content: center;
  gap: 0.625rem;
  border-radius: 0.5rem;
  background: linear-gradient(91deg, #413df6 9.91%, #413df6 91.56%);
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  border-color: $blue;
  color: $white;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  @media #{$xs-layout} {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
}
.welcome-txt {
  text-align: center;
  color: $white;
  padding-top: 2.5rem;
  font-size: 2.25rem;
  margin-bottom: 0.25rem;
}
.head-text {
  text-align: center;
  color: $white;
  font-size: 1.5rem;
  margin-bottom: 2.6rem;
  font-weight: 600;
}
.forget-password-text {
  color: $white;
  text-decoration: underline;
  margin: 0;
  text-align: flex-end;
  @media #{$xs-layout} {
    text-align: start;
    margin-top: 0.7rem;
    font-size: 13px;
  }
}
.resend-text {
  color: $white;
  margin: 0;
  text-align: center;
  cursor: pointer;
}
.action-wrapper {
  justify-content: center;
  display: flex;
  margin-top: 1.94rem;
  color: $white;
  a {
    color: $blue-1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
